import React from "react";
import MaterialTable from "material-table";
import { Button } from "react-bootstrap";
import {NotificationContainer, NotificationManager} from "react-notifications";
import API from "../../utils/API";
import "./../../notification.css";
const { Player } = require("../../utils/config.js");

export class TeacherListGame extends React.Component {
  state = {
    games: [],
    games_total_score: [],
    disable_edit: false,
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };


  calcul = (in_roles,infos_tour,param) => {
    let initialState = {
      dde_client: [],
      stock_fp: [],
      cde_frn: [],
      cout: [],
      label: [],
      calcul_cout: [],
      cout_rupture_cumule: [],
      cout_stock_cumule: [],
      rupture: [],
      cout_retard_commande_cumule: []
    };
  
    let roles = Object.keys(in_roles).map(key => ({ name: key, value: in_roles[key] }));
    let finalState = roles.reduce((acc, _, rnb) => {
      let info_tour = infos_tour[rnb];
  
      let dde_client_tmp = [];
      let stock_fp_tmp = [];
      let cde_frn_tmp = [];
      let cout_tmp = [];
      let label_tmp = [];
      let calcul_cout_tmp = 0;
      let cout_rupture_cumule_tmp = [];
      let cout_stock_cumule_tmp = [];
      let rupture_tmp = [];
      let cout_retard_commande_cumule_tmp = [];
  
      info_tour.forEach((tour, i) => {
        dde_client_tmp.push(tour.demande_client);
        stock_fp_tmp.push(tour.etat_stock_fin_period);
        cde_frn_tmp.push(tour.commande_fournisseur);
        rupture_tmp.push(-Number(tour.reste_a_servir_client));
  
        let cout_rupture = Number(tour.reste_a_servir_client) * param.par_cout_rupture_stock;
        let cout_stock = Number(tour.stock_debut_de_period) * (rnb === 0 ? param.par_cout_stock_periode_last_role : param.par_cout_stock_periode);
        let cout_retard = tour.commande_fournisseur_timeout ? (Number(tour.commande_fournisseur) * param.par_cout_transport) : 0;
  
        cout_rupture_cumule_tmp.push(cout_rupture);
        cout_stock_cumule_tmp.push(cout_stock);
        cout_retard_commande_cumule_tmp.push(cout_retard);
  
        calcul_cout_tmp += cout_rupture + cout_stock + cout_retard;
        cout_tmp.push(calcul_cout_tmp);
        label_tmp.push(`Tour ${i + 1}`);
      });
  
      acc.dde_client.push(dde_client_tmp);
      acc.stock_fp.push(stock_fp_tmp);
      acc.cde_frn.push(cde_frn_tmp);
      acc.cout.push(cout_tmp);
      acc.label.push(label_tmp);
      acc.calcul_cout.push(calcul_cout_tmp);
      acc.cout_rupture_cumule.push(cout_rupture_cumule_tmp);
      acc.cout_stock_cumule.push(cout_stock_cumule_tmp);
      acc.rupture.push(rupture_tmp);
      acc.cout_retard_commande_cumule.push(cout_retard_commande_cumule_tmp);
  
      return acc;
    }, initialState);
    console.log("nb de score ?", finalState.calcul_cout, finalState.calcul_cout.length);
    const somme = finalState.calcul_cout.reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante, 0);
    return somme;
  }
  
  majscore = async () => {
    let updatedGames = [];

      console.log("nb games ? ",this.state.games.length);
      for (let game of this.state.games) {
        try {

          const exo = game.exo;
         if (! typeof exo === 'number') {
            continue;
         }
          const parsed = { exo: exo };
          console.log(parsed.exo.toString());
    
          // Fetching all game rounds
          const allGamesRoundsData = await API.getallgamesrounds({ exo: parsed.exo });
          const { infos_tour, param } = allGamesRoundsData.data;
          if (!infos_tour) {
            updatedGames.push({
              ...game,
              score: "N/A"
            });
            continue;
          }

          // Fetching roles associated with the game
          const roleListData = await API.getrolelist({ exo: parsed.exo });
          let roles = roleListData.data.roles;
          delete roles[0]; // Assuming you're removing the first role for some reason       
    
          // Collect the total after calculation (if 'calcul' function updates the state)
          const totalForThisExo = this.calcul(roles,infos_tour,param);
    
          // Store the result for this game
          console.log("game value :",game);
          console.log("game score :",totalForThisExo);


          updatedGames.push({
            ...game,
            score: totalForThisExo
          });
    
      } catch (err) {
        console.log("majscore erreur",err);
      }
    }
    this.setState({
      games: updatedGames, // Store all the results for further use
    });
    console.log("fini d'update les scores");

  }

  list = () => {
    API.getmygames()
      .then(data => this.setState({ games: data.data.games, disable_edit: data.data.disable_edit }))
      .catch(err => {
        console.log(err);
        return null;
      });
  };

  createnewexo = async () => {
    try {
      await API.creategame();
      NotificationManager.success('Création réussi !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
    }
  };

  remove = async (oldData) => {
    const exo = oldData.exo;

    try {
      await API.removegame({ exo });
      NotificationManager.success('Suppression réussi !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }
  }

  reset = async (oldData) => {
    const exo = oldData.exo;

    try {
      await API.reset({ exo });
      NotificationManager.success('Remise à zero réussi !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }
  }

  update = async (newData, OldData) => {
    const email = OldData.email;
    const role = Player;
    const code = newData.code;
    const password = newData.password
    try {
      await API.updateplayer({ email, code, role, password });
      NotificationManager.success('Mise à jour réussie !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }

  }

  duplicate = async (exo) => {
    try {
      await API.cpygames({ exo });
      NotificationManager.success('Copie réussie !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }
  }


  pause = async (exo) => {
    try {
      await API.pausegame({ exo });
      NotificationManager.success('mise en pause réussie !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }
  }


  unpause = async (exo) => {
    try {
      await API.unpausegame({ exo });
      NotificationManager.success('relance fin de pause réussie !', 'gestion des jeux');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des jeux', 2000);
      console.log(error.response); 
    }
  }

  editgame = (exo) => {
    window.location = "/dashboard?exo="+exo;
  };

  switchtousers = () => {
    window.location = "/teacherpanel";
  };

  viewGraph = (exo) => {
    window.location = "/scoreview?exo="+exo;
  };


  componentDidMount() {
    this.list();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.games && this.state.games.length > 0) {
        if (prevState.games.length !== this.state.games.length) {
          await this.majscore();  // Met à jour les scores pour les jeux
        }
    }
  }

  render() {
    console.log("render games");
    console.log(this.state.games);
    return (
      <div className="TeacherListGame">
        {(
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h1>Création des parties</h1>

            {this.state.disable_edit !== true ? (

            <MaterialTable
              title="Liste des parties"
              columns={[
                { title: "Numéro", field: "exo" },
                { title: "En pause", field: "pause", render: rowData => (rowData.pause ? "En pause" : "En jeu"),              },
                { title: "Nom de la partie", field: "exoName"},
                { title: "Avancement, numéro de période", field: "period", defaultSort: "desc"},
                { title: "Score total", field: "score" }
              ]}
              data={this.state.games}              
              actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Paramétrer',
                    onClick: (event, rowData) => this.editgame(rowData.exo)
                  },
                  {
                    icon: 'library_add',
                    tooltip: 'Dupliquer',
                    onClick: (event, rowData) => this.duplicate(rowData.exo)
                  },
                  {
                    icon: 'cached',
                    tooltip: 'Remettre à zero',
                    onClick: (event, rowData) => 
                    { if (window.confirm('Êtes-vous sûr de vouloir remettre à zero cette partie ?')) this.reset(rowData) }
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Supprimer',
                    onClick: (event, rowData) => 
                    { if (window.confirm('Êtes-vous sûr de vouloir supprimer cette partie ?')) this.remove(rowData) }
                  },
                  {
                    icon: 'timeline',
                    tooltip: 'Visualiser',
                    onClick: (event, rowData) => this.viewGraph(rowData.exo)
                  },
                  rowData=>({
                    icon: rowData.pause? 'play_arrow': 'pause',
                    tooltip: rowData.pause?'Relancer':'Pause',
                    onClick: (event, rowData) => rowData.pause? this.unpause(rowData.exo):this.pause(rowData.exo)
                  }),
                  {
                    icon: 'add',
                    tooltip: 'Ajouter un exercice',
                    isFreeAction: true,
                    onClick: (event) => {
                      this.createnewexo();
                    }
                  }
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Aucun jeu - ajoutez un premier en utilisant le + en haut à droite",
                }
              }}
            />
            ) : (
              <MaterialTable
              title="Liste des parties"
              columns={[
                { title: "Numéro", field: "exo" },
                { title: "Nom de la partie", field: "exoName"},
                { title: "Avancement, numéro de période", field: "period", defaultSort: "desc"},
                { title: "Score total", field: "score"}
              ]}
              data={this.state.games}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Ajouter un exercice',
                  isFreeAction: true,
                  onClick: (event) => {
                    this.createnewexo();
                  }
                }
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Aucun jeu - ajoutez un premier en utilisant le + en haut à droite",
                }
              }}

            />
            )}
            
            <NotificationContainer/>

            <Button
              onClick={this.switchtousers}
              block
              bsSize="large"
              type="submit"
            >
              Gérer les joueurs
            </Button>

            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>
          </div>
        )}
      </div>
    )}
  }
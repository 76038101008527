import React from "react";
import MaterialTable from "material-table";
import {Select, MenuItem  } from '@material-ui/core';
import { Button } from "react-bootstrap";
import {NotificationContainer, NotificationManager} from "react-notifications";
import API from "../../utils/API";
import "./../../notification.css";
const { Player,Role } = require("../../utils/config.js");


export class TeacherPanel extends React.Component {
  state = {
    users: [],
    roles: [],
    exos: [],
    allRoles: [],
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  list = () => {
    API.listallmyplayers()
      .then(data => {
        this.setState({ users: data.data.users });
      })
      .catch(err => {
        console.log(err);
        return null;
      });
    API.listallmygamesnumber().then( data => {
      this.setState({ exos: data.data.exos });
      let allRoles=new Map();
      if (data.data.exos != null && data.data.exos !== undefined) {
        Object.keys(data.data.exos).map((key, index) => {
          API.getrolelist({ exo: key }).then(data => {
            allRoles[key]=data.data.roles;
          })
          return 0;
        });
      }
      this.setState({allRoles: allRoles});
    })
    .catch(err => {
      console.log(err);
      return null;
    });
  };

  signup = async (newData) => {
    if (!newData.email || newData.email.length === 0) return;
    if (!newData.password || newData.password.length === 0 ) return;
    const role = Player;
    const code = newData.code;
    const password = newData.password;
    const gamerole = newData.gamerole;
    const exo = newData.exo;
    const email = newData.email;

    if (!email || !role || !password || !gamerole || !exo) {
      NotificationManager.error('entrée invalide, paramètre manquant', 'gestion des joueurs', 2000);
    } else {
      try {
        await API.signupplayer({ email, code, role, password, gamerole, exo });
        NotificationManager.success('Ajout réussi !', 'gestion des joueurs');
        this.list();
      } catch (error) {
        NotificationManager.error(error.response.data.text, 'gestion des joueurs', 2000);
        console.log(error.response); 
      }
    }
  };

  remove = async (oldData) => {
    const email = oldData.email;
    const role = Player;
    const code = oldData.code;
    try {
      await API.removeplayer({ email, code, role });
      NotificationManager.success('Suppression réussi !', 'gestion des joueurs');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des joueurs', 2000);
      console.log(error.response); 
    }
  }

  update = async (newData, OldData) => {
    const email = OldData.email;
    const role = Player;
    const code = newData.code;
    const password = newData.password;
    const gamerole = newData.gamerole;
    const exo = newData.exo;

    if (!email || (role<0) || !password || !gamerole || (exo<0) ) {
      NotificationManager.error('entrée invalide, paramètre manquant', 'gestion des joueurs', 2000);
    } else {

      try {
        await API.updateplayer({ email, code, role, password, gamerole, exo });
        NotificationManager.success('Mise à jour réussie !', 'gestion des joueurs');
        this.list();
      } catch (error) {
        NotificationManager.error(error.response.data.text, 'gestion des joueurs', 2000);
        console.log(error.response); 
      }
    }

  }

  componentDidMount() {
    this.list();
  }

  switchtoexerices = () => {
    window.location = "/TeacherListGame";
  };

  getMenuItemFromExo = (exo) => {
    if (this.state.allRoles[exo]===undefined){
      return Object.keys(Role).map(a => (
        <MenuItem key={a} value={Role[a]}> { Role[a] }</MenuItem>
      ));
    }
    return Object.keys(this.state.allRoles[exo]).map(a => (
      <MenuItem key={a} value={this.state.allRoles[exo][a]}> { this.state.allRoles[exo][a] }</MenuItem>
    ));
  }

  render() {
    return (
      <div className="TeacherPanel">
        {this.state.allRoles===undefined || this.state.allRoles.length === 0 ? (
          <div>Chargement en cours ...</div>
        ) : (
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h1>Création des joueurs</h1>
            <MaterialTable
              title="Liste des joueurs"
              columns={[
                { title: "identifiant", field: "email" },
                { title: "numéro d'exercice", field: "exo", lookup: this.state.exos},
                { title: "role", field: "gamerole", 
                  editComponent: t =>
                    (
                      <Select
                        value={t.value}
                        onChange={e => {
                          t.onChange(e.target.value);
                          console.group(e.target.value);
                      //    t.value = e.target.value;
                        }}
                      >
                            { this.getMenuItemFromExo(t.rowData.exo) }
                        </Select>
                    )
                },
                { title: "mot de passe", field: "password" }
              ]}              
              data={this.state.users}
              editable={{
                onRowAdd: newData =>
                  new Promise(resolve => {
                    this.signup(newData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    this.update(newData,oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    this.remove(oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Aucun joueur - ajoutez un premier en utilisant le + en haut à droite",
                }
              }}
            />
            <NotificationContainer/>

            <Button
              onClick={this.switchtoexerices}
              block
              bsSize="large"
              type="submit"
            >
              Gérer les exercices
            </Button>

            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>

          </div>
        )}
      </div>
    );
  }
}

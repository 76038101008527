import React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import API from "../../utils/API";
const { Role } = require("../../utils/config.js");

export class GameRoleChooser extends React.Component {
  state = {
    exo: 0,
    gamerole: "N/A",
    roles: Role,
    games: [],
    enableChoice: false,
    exoChoice: false,
    name: "joueur"
  };

  checkGameExist = (games,exo) => {
    let exist=false;
    Object.values(games).map((game) =>
      (Number(game)===Number(exo))?exist=true:''
    );
    if (!exist) {
      this.setState( {
        exo: this.state.games[0],
        exoChoice: true,
      });
    }
  }

  getme = async () => {
    await API.me().then( data => {
        this.setState(
        {
          gamerole: data.data.me.gamerole,
          enableChoice: String(data.data.me.gamerole)===String("N/A"),
          exo: (data.data.me.exo===0)?this.state.games[0]:data.data.me.exo,
          exoChoice: (data.data.me.exo===0),
          name: data.data.me.email
        });
        this.checkGameExist(this.state.games,this.state.exo);

        API.getrolelist({ exo: this.state.exo }).then(data => {
          this.setState({roles: data.data.roles});
        })

      }
    ).catch(err => { console.log(err); return null; 
    });
  }

  list = async () => {
    await API.listallmygamesnumber()
      .then(data => {
        this.setState({ games: data.data.exos });
        this.getme();
        if (this.state.enableChoice===false){
          window.location = "/gamerview";
        }
      })
      .catch(err => {
        console.log(err);
        return null;
      });
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  choose = async () => {
    await API.updaterole({ exo: this.state.exo, role: this.state.gamerole })
      .then(data => {
        window.location = "/gamerview";
      })
      .catch(err => {
        console.log(err);
        return null;
      });
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    API.getrolelist({ exo: this.state.exo }).then(data => {
      this.setState({roles: data.data.roles});
    })
  };

  componentDidMount() {
    this.list();
  }

  render() {
    const { exo, gamerole, games, roles, enableChoice, exoChoice } = this.state;

    return (
      <div className="GameRoleChooser">
        {this.state.games.length === 0 && this.state.gamerole.length === 0 ? (
          <div>Chargement en cours ...</div>
        ) : (
        <div>
        <h1>Choix de l'exercice et du rôle pour {this.state.name}</h1>
        <FormGroup controlId="exo" bsSize="sm">
          <ControlLabel>Exercice</ControlLabel>
          <FormControl
            componentClass="select"
            value={exo}
            onChange={this.handleChange}
            disabled={!exoChoice}
          >
            {Object.values(games).map((game) => <option key={game} value={game}>{game}</option>)}
          </FormControl>
        </FormGroup>
        <FormGroup controlId="gamerole" bsSize="sm">
          <ControlLabel>le Role</ControlLabel>
          <FormControl
            componentClass="select"
            value={gamerole}
            onChange={this.handleChange}
            disabled={!enableChoice}
          >
            {Object.keys(roles).map((role) => <option key={roles[role]} value={roles[role]}>{roles[role]}</option>)}

          </FormControl>
        </FormGroup>
        <Button onClick={this.choose} block bsSize="large" type="submit">
          Valider mon choix
        </Button>
        <Button onClick={this.disconnect} block bsSize="large" type="submit">
          Se déconnecter
        </Button>
        </div>
        )}
        </div>
      );
    }
  }

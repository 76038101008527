import React from "react";
import { Button } from "react-bootstrap";
import API from "../../utils/API";
import "./../../notification.css";


export class TeacherStartPanel extends React.Component {
  state = {
    users: []
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  switchtousers = () => {
    window.location = "/teacherpanel";
  };

  switchtoexerices = () => {
    window.location = "/TeacherListGame";
  };

  render() {
    return (
      <div className="TeacherStartPanel">
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h1>Bienvenue</h1>
            <Button
              onClick={this.switchtoexerices}
              block
              bsSize="large"
              type="submit"
            >
              Gérer les exercices
            </Button>
            <Button
              onClick={this.switchtousers}
              block
              bsSize="large"
              type="submit"
            >
              Gérer les joueurs
            </Button>
            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>
          </div>
      </div>
    );
  }
}

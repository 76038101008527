import React from "react";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup
} from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import API from "../../utils/API";
import i18n from '../../utils/i18n_gameView';

const queryString = require("query-string");

export class Dashboard extends React.Component {
  state = {
    par_delai_livr_cde: 0,
    par_cout_rupture_stock: 0,
    par_cout_stock_periode: 0,
    par_cout_stock_periode_last_role: 0,
    par_cout_transport: 0,
    par_afficher_tdb: true,
    par_voir_reliquat_fournisseur : true,
    par_periodes:1,
    par_language: i18n.lang_fr,
    _id: -1,
    par_chrono: 600,
    exoName: "exercice"
  };

  switchtousers = () => {
    window.location = "/teacherpanel";
  };

  switchtoexerices = () => {
    window.location = "/TeacherListGame";
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangelang = event => {
    this.setState({
      par_language: parseInt(event.target.value, 10)
    });
  };

  togglecheckbox = event => {
    var a = 1;
    if (this.state[event.target.id]) {
      a = 0;
    }
    this.setState({
      [event.target.id]: a
    });
  };

  nextparam = () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);
    new Promise(resolve => {
      this.update();
      setTimeout(() => {
        resolve();
        window.location = "/periodview?exo="+parsed.exo;
      }, 2000 );
    });
  };

  roleparam = () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);
    new Promise(resolve => {
      this.update();
      setTimeout(() => {
        resolve();
        window.location = "/roleview?exo="+parsed.exo;
      }, 2000 );
    });
  };

  update = async () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);
    try {
      await API.updategameparam({ params: this.state, exo: parsed.exo });
      await NotificationManager.success(
        "Mise à jour réussie !",
        "gestion des exercices"
      );
    } catch (error) {
      NotificationManager.error(
        error.response.data.text,
        "gestion des exercices",
        2000
      );
      this.list();
      console.log(error.response);
    }
  };

  list = () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);
    API.getgameparam({ exo: parsed.exo })
      .then(data => this.setState({
        par_delai_livr_cde: data.data.params.par_delai_livr_cde,
        par_cout_rupture_stock: data.data.params.par_cout_rupture_stock,
        par_cout_stock_periode: data.data.params.par_cout_stock_periode,
        par_cout_stock_periode_last_role: data.data.params.par_cout_stock_periode_last_role,
        par_cout_transport: data.data.params.par_cout_transport,
        par_afficher_tdb:  data.data.params.par_afficher_tdb,
        par_voir_reliquat_fournisseur : data.data.params.par_voir_reliquat_fournisseur,
        par_periodes : data.data.params.par_periodes,
        par_chrono: data.data.params.par_chrono,
        par_language: data.data.params.par_language,
        _id: data.data.params._id,
        exoName: data.data.exoName,
      }))
      .catch(err => {
        console.log(err);
        return null;
      });
  };

  componentDidMount() {
    this.list();
  }

  render() {
    const {
     // par_delai_livr_cde,
      par_cout_rupture_stock,
      par_cout_stock_periode,
      par_cout_stock_periode_last_role,
      par_cout_transport,
      // eslint-disable-next-line
      par_afficher_tdb,
      // eslint-disable-next-line
      par_voir_reliquat_fournisseur,
      par_periodes,
      par_chrono,
      par_language,
      exoName
    } = this.state;

    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);

    return (
      <div className="Dashboard">
        {this.state._id === -1 ? (
          <div>Chargement en cours ...</div>
        ) : (
          <div>
            <h1>Configuration de l'exercice : {parsed.exo} </h1>

            <FormGroup controlId="exoName" bsSize="large">
              <ControlLabel>Nom de la partie</ControlLabel>
              <FormControl
                autoFocus
                type="String"
                value={exoName}
                onChange={this.handleChange}
              />
            </FormGroup>

            {/* <FormGroup controlId="par_delai_livr_cde" bsSize="large">
              <ControlLabel>Délais de livraison</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={1}
                value={par_delai_livr_cde}
                onChange={this.handleChange}
              />
              <InputGroup.Addon>Semaines</InputGroup.Addon>
            </FormGroup> */}

            <FormGroup controlId="par_language" bsSize="large">
              <ControlLabel>Langue</ControlLabel>
              <FormControl componentClass="select" placeholder="select" value={par_language} onChange={this.handleChangelang} type="number">
              <option value={+0}>Français</option>
              <option value={+1}>Anglais</option>
              </FormControl>
            </FormGroup>

            <FormGroup controlId="par_cout_rupture_stock" bsSize="large">
              <ControlLabel>Coût/j des ruptures</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={0.01}
                value={par_cout_rupture_stock}
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup controlId="par_cout_stock_periode" bsSize="large">
              <ControlLabel>Coût/j des stocks</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={0.01}
                value={par_cout_stock_periode}
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup controlId="par_cout_stock_periode_last_role" bsSize="large">
              <ControlLabel>Coût/j des stocks pour le rôle le plus proche du client</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={0.01}
                value={par_cout_stock_periode_last_role}
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup controlId="par_cout_transport" bsSize="large">
              <ControlLabel>Coût de transport des commandes hors délais</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={0.01}
                value={par_cout_transport}
                onChange={this.handleChange}
              />
            </FormGroup>

            {/* <FormGroup controlId="par_afficher_tdb" bsSize="sm">
              <ControlLabel>Affichage de la demande</ControlLabel>
              <FormControl
                autoFocus
                type="checkbox"
                // checked={par_afficher_tdb}
                checked={false}
                // value={par_afficher_tdb}
                value={false}
                onChange={this.togglecheckbox}
              />
            </FormGroup> */}

            <FormGroup controlId="par_voir_reliquat_fournisseur" bsSize="sm">
              <ControlLabel>Affichage reste à livrer du fournisseur</ControlLabel>
              <FormControl
                autoFocus
                type="checkbox"
                checked={par_voir_reliquat_fournisseur}
                //checked={false}
                value={par_voir_reliquat_fournisseur}
                //value={false}
                onChange={this.togglecheckbox}
              />
            </FormGroup>
            <FormGroup controlId="par_periodes" bsSize="large">
              <ControlLabel>Nombre de périodes</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={1}
                value={par_periodes}
                onChange={this.handleChange}
              />
              </FormGroup>
            <FormGroup controlId="par_chrono" bsSize="large">
              <ControlLabel>duree maximum des périodes</ControlLabel>
              <FormControl
                autoFocus
                type="number"
                step={1}
                value={par_chrono}
                onChange={this.handleChange}
              />
              <InputGroup.Addon>Secondes</InputGroup.Addon>
            </FormGroup>

            <Button
              onClick={this.roleparam}
              block
              bsSize="large"
              type="submit"
            >
              Sauvegarder la configuration
              <br/>
              Paramétrer les roles & commandes
            </Button>
            <Button
              onClick={this.switchtousers}
              block
              bsSize="large"
              type="submit"
            >
              Afficher la gestion des joueurs
            </Button>
            <Button
              onClick={this.switchtoexerices}
              block
              bsSize="large"
              type="submit"
            >
              Afficher la gestion des exercices
            </Button>
            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>

            <NotificationContainer />
          </div>
        )}
      </div>
    );
  }
}

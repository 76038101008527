import React from "react";
import MaterialTable from "material-table";
import { Button } from "react-bootstrap";
import {NotificationContainer, NotificationManager} from "react-notifications";
import API from "../../utils/API";
import "./../../notification.css";
const { Teacher } = require("../../utils/config.js");


export class AdminPanel extends React.Component {
  state = {
    users: [],
    receive: false
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  list = () => {
    this.setState({ receive: false });
    API.getallteacher()
      .then(data => this.setState({ users: data.data.users, receive:true }))
      .catch(err => {
        return null;
      });
  };

  signup = async (newData) => {
    if (!newData.email || newData.email.length === 0) return;
    if (!newData.password || newData.password.length === 0 ) return;
    const email = newData.email;
    const password = newData.password;
    const code = newData.code;
    try {
      await API.signupteacher({ email, password, code });
      NotificationManager.success('Ajout réussi !', 'gestion des intervenants');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des intervenants', 2000);
      console.log(error.response); 
    }
  };

  remove = async (oldData) => {
    const email = oldData.email;
    const role = Teacher;
    const code = oldData.code;
    try {
      await API.removeteacher({ email, code, role });
      NotificationManager.success('Suppression réussi !', 'gestion des intervenants');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des intervenants', 2000);
      console.log(error.response); 
    }
  }

  update = async (newData, OldData) => {
    const email = OldData.email;
    const role = Teacher;
    const code = newData.code;
    const password = newData.password
    try {
      await API.updateteacher({ email, code, role, password });
      NotificationManager.success('Mise à jour réussie !', 'gestion des intervenants');
      this.list();
    } catch (error) {
      NotificationManager.error(error.response.data.text, 'gestion des intervenants', 2000);
      console.log(error.response); 
    }

  }

  componentDidMount() {
    this.list();
  }

  render() {
    return (
      <div className="AdminPanel">
        {this.state.receive === false ? (
          <div>Chargement en cours ...</div>
        ) : (
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h1>Création des intervenants</h1>

            <MaterialTable
              localization={{
                  body: {
                      emptyDataSourceMessage: (
                        <div>
                        Créez un premier intervenant en cliquant sur le +
                      </div>
                      ),
                  },
              }}
              title="Liste des intervenants"
              columns={[
                { title: "identifiant", field: "email" },
                { title: "code rattachement", field: "code"},
                { title: "mot de passe", field: "password" }
              ]}
              data={this.state.users}
              editable={{
                onRowAdd: newData =>
                  new Promise(resolve => {
                    this.signup(newData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    this.update(newData,oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    this.remove(oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  })
              }}
            />
                        <NotificationContainer/>

            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>
          </div>
        )}
      </div>
    );
  }
}

    /* App.js */
	import React from "react";
	import Chart from "chart.js";
	// eslint-disable-next-line
	import ChartDataLabels from 'chartjs-plugin-datalabels';
	import API from "../../utils/API";
	import { Button } from "react-bootstrap";

	const queryString = require("query-string");


    export class GamerScore extends React.Component {
		chartBars = React.createRef();
		chartCout = React.createRef();

		state = {
			exo: 0,
			role: 0,
			param: 0,
			tbd: 0,
			par_voir_reliquat_fournisseur: 0,
			infos_tour: [],
			tour: 0,
			last_tour: false,
			dde_client:[],
			stock_fp:[],
			cde_frn:[],
			cout:[],
			label:[],
			cout_rupture_cumule: [],
			cout_stock_cumule: [],
			cout_retard_commande_cumule: [],
			rupture: [],
		};

		calcul = () => {
			let dde_client=[];
			let stock_fp=[];
			let cde_frn=[];
			let rupture=[];
			let cout=[];
			let label=[];
			let calcul_cout=0;
			let cout_rupture_cumule=[];
			let cout_stock_cumule=[];
			let cout_retard_commande_cumule=[];

			for (var i=1; i< this.state.infos_tour.length; i++) {
				dde_client.push(this.state.infos_tour[i].demande_client);
				stock_fp.push(this.state.infos_tour[i].etat_stock_fin_period);
				cde_frn.push(this.state.infos_tour[i].commande_fournisseur);
				rupture.push(-Number(this.state.infos_tour[i].reste_a_servir_client));
				let cout_rupture = (Number(this.state.infos_tour[i].reste_a_servir_client)*this.state.param.par_cout_rupture_stock);
				//TODO : vérifier le role & (rnb === 1 ? this.state.param.par_cout_stock_periode_last_role : this.state.param.par_cout_stock_periode);
				let coutStockPeriode = 0;
				if (this.state.role == 1) {
					coutStockPeriode = this.state.param.par_cout_stock_periode_last_role;
				} else {
					coutStockPeriode = this.state.param.par_cout_stock_periode;
				}
				let cout_stock = (Number(this.state.infos_tour[i].stock_debut_de_period)*coutStockPeriode);
				let cout_retard = (this.state.infos_tour[i].commande_fournisseur_timeout)?(Number(this.state.infos_tour[i].commande_fournisseur)*this.state.param.par_cout_transport):0;
				cout_rupture_cumule.push(cout_rupture);
				cout_stock_cumule.push(cout_stock);
				cout_retard_commande_cumule.push(cout_retard);
				calcul_cout=calcul_cout+cout_rupture+cout_stock+cout_retard;
				cout.push(calcul_cout);
				label.push("Tour"+i);
			}
			this.setState({dde_client: dde_client, rupture: rupture, stock_fp: stock_fp, cde_frn: cde_frn, cout: cout,cout_stock_cumule: cout_stock_cumule, cout_rupture_cumule: cout_rupture_cumule,cout_retard_commande_cumule: cout_retard_commande_cumule, label: label});
			console.log(this.state);
		}

		list = async () => {
			const parsed = queryString.parse(window.location.search);
			console.log(parsed.exo.toString());
			this.setState({ exo: parsed.exo, role: parsed.role });
			console.log(this.state.exo);
		
			await API.getonlygameplayer({ exo: parsed.exo })
			  .then(data => {
				this.setState({
				infos_tour: data.data.infos_tour, 
				param: data.data.param, 
				tbd: data.data.tbd, 
				par_voir_reliquat_fournisseur: data.data.par_voir_reliquat_fournisseur,
				last_tour: data.data.last_tour,
				  });
				this.calcul();
				})
			  .catch(err => {
				console.log(err);
				return null;
			  });
		  };
	
		componentDidMount() {	
			
			this.list();
		  }

		retour = () => {
			const parsed = queryString.parse(window.location.search);
			console.log(parsed.exo.toString());
			this.setState({ exo: parsed.exo, role: parsed.role });
			console.log(this.state.exo);
			window.location = "/gamerview?exo="+this.state.exo;
		}

    	render() {
			var chartColors = {
				red: 'rgb(255, 99, 132)',
				orange: 'rgb(255, 159, 64)',
				yellow: 'rgb(255, 205, 86)',
				green: 'rgb(75, 192, 192)',
				blue: 'rgb(54, 162, 235)',
				purple: 'rgb(153, 102, 255)',
				grey: 'rgb(201, 203, 207)'
			};
			var chartData = {
				labels: this.state.label,
				datasets: [{
					type: 'bar',
					label: 'Demande Client',
					backgroundColor: chartColors.yellow,
					data: this.state.dde_client,
					borderColor: 'white',
					borderWidth: 2,
					datalabels: {
					}
				},
				{
					type: 'bar',
					label: 'Commande fournisseur',
					backgroundColor: chartColors.green,
					data: this.state.cde_frn,
					datalabels: {
					}
				}, {
					type: 'bar',
					label: 'Stock fin de période',
					backgroundColor: chartColors.orange,
					data: this.state.stock_fp,
					borderColor: 'white',
					borderWidth: 2,
					datalabels: {
					}
				},
				{
					type: 'bar',
					label: 'Reliquat à livrer au client',
					backgroundColor: chartColors.red,
					data: this.state.rupture,
					borderColor: 'white',
					borderWidth: 2,
					datalabels: {
					}
				}
				]
	
			};
			var lineData = {
				labels: this.state.label,
				datasets: [{
						type: 'line',
						label: 'Coût total',
						borderColor: chartColors.blue,
						borderWidth: 2,
						fill: false,
						data: this.state.cout,
						datalabels: {
						},
					},
					{
						type: 'line',
						label: 'Coût Stock',
						borderColor: chartColors.orange,
						borderWidth: 2,
						fill: false,
						data: this.state.cout_stock_cumule
						,
						datalabels: {
						},
					},
					{
						type: 'line',
						label: 'Coût Rupture',
						borderColor: chartColors.red,
						borderWidth: 2,
						fill: false,
						data: this.state.cout_rupture_cumule
						,
						datalabels: {
						},
					},
					{
						type: 'line',
						label: 'Coût Transport',
						borderColor: chartColors.green,
						borderWidth: 2,
						fill: false,
						data: this.state.cout_retard_commande_cumule
						,
						datalabels: {
						},
					},
				]
	
			};
			if (this.state.infos_tour.length !== 0) {
				const myChartRef = this.chartBars.current.getContext("2d");
				new Chart(myChartRef, {
					type: 'bar',
					data: chartData,
					options: {
						responsive: true,
						title: {
							display: true,
							text: 'Score'
						},
						plugins: {
							datalabels: {
								align: 'top' ,
								offset: 10,
								backgroundColor: chartColors.blue,
								borderRadius: 4,
								color: 'white',
								font: {
									weight: 'bold'
								},
							}
						},
					}
				});
				const myChartLineRef = this.chartCout.current.getContext("2d");
				new Chart(myChartLineRef, {
					type: 'line',
					data: lineData,
					options: {
						responsive: true,
						title: {
							display: true,
							text: 'Score'
						},
						plugins: {
							datalabels: {
								align: 'top' ,
								offset: 10,
								backgroundColor: chartColors.blue,
								borderRadius: 4,
								color: 'white',
								font: {
									weight: 'bold'
								},
							}
						},
					}
				});
			}	
    		return (
                <div className="GamerScore">
        		{this.state.infos_tour.length === 0 ? (
					 <div>Chargement en cours ...
					 <canvas
                    	id="myChart"
						ref={this.chartBars}
                	/> 
					<canvas
						id="myChartLine"
						ref={this.chartCout}
					/>
					</div>
					 ) : (
					<div>
					 <canvas
                    	id="myChartBar"
                    	ref={this.chartBars}
                	/>
					<canvas
					id="myChartLine"
					ref={this.chartCout}
					/>
					</div>
				)}
    			<div>
				<Button onClick={this.retour} block bsSize="large" type="submit">
					Revenir à la partie
				</Button>
				<br></br>

    		</div>
            </div>
    		);
		}
		
    }
import React from "react";
import MaterialTable from "material-table";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import API from "../../utils/API";
import "./../../notification.css";
const queryString = require("query-string");
let indexval = 1;

export class PeriodView extends React.Component {
  state = {
    roles: [],
    params: [],
    columns: [],
    datas: [],
    delais: [],
    expeditions: [],

    exo: 0,
    game: 0,
    param: 0,
    commandes: [],
    par_RxSecondTourProd: 0,
  };

  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  retour = () => {
    window.location = "/dashboard?exo=" + this.state.exo;
  };

  get = () => {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.exo);
    API.getgameparam({ exo: parsed.exo })
      .then((data) => {
        this.setState({
          params: data.data.params,
          roles: data.data.roles,
          par_RxSecondTourProd: data.data.roles.RxSecondTourProd,
          delais: data.data.delais,
          expeditions: data.data.expeditions,
        });
        this.list();
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  createnewrole = async (role) => {
    try {
      const parsed = queryString.parse(window.location.search);
      await API.createrole({ exo: parsed.exo, role: role });
      NotificationManager.success("Création réussi !", "gestion des rôles");
      this.get();
    } catch (error) {
      NotificationManager.error("Création échouée !", "gestion des rôles");
      console.log(error);
    }
  };

  upRole = async (role) => {
    // on retrouve l'index dans la liste
    let index = this.state.roles.nameRoles.indexOf(role.nameRoles);
    let tmpRoles = this.state.roles;
    let indexOrdre = tmpRoles.ordreRoles.indexOf(index);
    if (indexOrdre <= 1) return;

    let prevVal = tmpRoles.ordreRoles[indexOrdre - 1];
    tmpRoles.ordreRoles[indexOrdre - 1] = index;
    tmpRoles.ordreRoles[indexOrdre] = prevVal;

    this.setState({ roles: tmpRoles });

    try {
      const parsed = queryString.parse(window.location.search);
      await API.changeroleorder({
        exo: parsed.exo,
        newOrder: this.state.roles.ordreRoles,
      });
      NotificationManager.success(
        "Changement d'ordre réussi !",
        "gestion des rôles"
      );
      this.get();
    } catch (error) {
      NotificationManager.error(
        "Change d'ordre échouée !",
        "gestion des rôles"
      );
      console.log(error);
    }
  };

  downRole = async (role) => {
    // on retrouve l'index dans la liste
    let index = this.state.roles.nameRoles.indexOf(role.nameRoles);
    let tmpRoles = this.state.roles;
    let indexOrdre = tmpRoles.ordreRoles.indexOf(index);
    if (indexOrdre >= tmpRoles.ordreRoles.length - 1) return;

    let prevVal = tmpRoles.ordreRoles[indexOrdre + 1];
    tmpRoles.ordreRoles[indexOrdre + 1] = index;
    tmpRoles.ordreRoles[indexOrdre] = prevVal;

    this.setState({ roles: tmpRoles });
    try {
      const parsed = queryString.parse(window.location.search);
      await API.changeroleorder({
        exo: parsed.exo,
        newOrder: this.state.roles.ordreRoles,
      });
      NotificationManager.success(
        "Changement d'ordre réussi !",
        "gestion des rôles"
      );
      this.get();
    } catch (error) {
      NotificationManager.error(
        "Change d'ordre échouée !",
        "gestion des rôles"
      );
      console.log(error);
    }
  };

  updateRxSecondTourProd = async (val) => {
    try {
      const parsed = queryString.parse(window.location.search);
      await API.updateRxSecondTourProd({
        exo: parsed.exo,
        RxSecondTourProd: val,
      });
      // NotificationManager.success(
      //   "modification de la livraison !",
      //   "gestion des rôles"
      // );
    } catch (error) {
      // NotificationManager.error(
      //   "modification de la livraison échouée !",
      //   "gestion des rôles"
      // );
      console.log(error);
    }
  };

  update = async (newRole, oldRole) => {
    console.log(newRole);

    // check de non doublon de nom
    if (newRole.nameRoles !== oldRole.nameRoles) {
      if (
        this.state.roles.nameRoles.filter(function (value) {
          return value === newRole.nameRoles;
        }).length >= 1
      )
        return false;
    }

    try {
      const parsed = queryString.parse(window.location.search);
      await API.updateroles({
        exo: parsed.exo,
        role: oldRole,
        uprole: newRole,
      });
      NotificationManager.success("Mise à jour réussie !", "gestion des rôles");
      this.get();
    } catch (error) {
      NotificationManager.error("Mise à jour échouée !", "gestion des rôles");
      console.log(error);
    }
  };

  remove = async (role) => {
    if (this.state.roles.nameRoles.length <= 5) {
      NotificationManager.error(
        "4 est le nombre minimum de roles",
        "gestion des rôles",
        3000
      );
      return;
    }

    try {
      const parsed = queryString.parse(window.location.search);
      await API.deleterole({ exo: parsed.exo, role: role.nameRoles });
      NotificationManager.success("effacement réussi !", "gestion des rôles");
      this.get();
    } catch (error) {
      NotificationManager.error("effacement échouée !", "gestion des rôles");
      console.log(error);
    }

    this.list();
  };

  switchtousers = () => {
    window.location = "/teacherpanel";
  };

  switchtoexerices = () => {
    window.location = "/TeacherListGame";
  };

  list_period = () => {
    const parsed = queryString.parse(window.location.search);
    this.setState({ exo: parsed.exo });
    API.getonlygame({ exo: parsed.exo })
      .then((data) =>
        this.setState({
          game: data.data.game,
          param: data.data.param,
          commandes: data.data.param.par_commandes,
        })
      )
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  list = () => {
    let table = [];
    let columns = [];
    let column = { title: "Nom du role", field: "nameRoles" };
    columns.push(column);
    column = { title: "Stock premier Tour", field: "stockRolePremierTour" };
    columns.push(column);
    column = { title: "Demande premier Tour", field: "demandePremierTour" };
    columns.push(column);

    for (let i = 1; i <= this.state.roles.nbRoles; i++) {
      let line = {
        nameRoles: this.state.roles.nameRoles[this.state.roles.ordreRoles[i]],
      };
      line["stockRolePremierTour"] = this.state.roles.stockRolePremierTour[
        this.state.roles.ordreRoles[i]
      ];
      line["demandePremierTour"] = this.state.roles.demandePremierTour[
        this.state.roles.ordreRoles[i]
      ];
      table.push(line);
    }
    this.setState({ datas: table });
    this.setState({ columns: columns });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id==="par_RxSecondTourProd") {
      if ( !isNaN(event.target.value) && event.target.value>=0 && event.target.value!=null && event.target.value!== undefined  && event.target.value!== "" ) {
        this.updateRxSecondTourProd(event.target.value);
      }
    }
  };

  NumberList = (props) => {
    const numbers = props.numbers;
    const listItems = numbers.map((number) => (
      <li key={number.toString()}>{number}</li>
    ));
    return <ul>{listItems}</ul>;
  };

  save = async (newVal) => {
    console.log(newVal);

    var tmp_commandes = [];

    for (var i = 1; i <= this.state.param.par_periodes; i++) {
      tmp_commandes[i] = newVal[i];
    }
    await this.setState({ commandes: tmp_commandes });
    console.log(this.state.param);
    try {
      await API.updategamecommandes({
        _id: this.state.param._id.toString(),
        par_commandes: this.state.commandes,
      });
      await NotificationManager.success(
        "Mise à jour réussie !",
        "gestion des exercices"
      );
    } catch (error) {
      NotificationManager.error(
        error.response.data.text,
        "gestion des exercices",
        2000
      );
      this.list_period();
      console.log(error.response);
    }
  };

  componentDidMount() {
    this.get();
    this.list_period();
  }

  render() {
    console.log("roles");

    const parsed = queryString.parse(window.location.search);
    const exo = parsed.exo;

    var columns = [];
    var tmp_commandes = [];
    let val = {
      title: "Numéro de période",
      field: "valeurs",
      type: "string",
      editable: "never",
      cellStyle: {
        width: 200,
        backgroundColor: "#7986cb",
        color: "#FFF",
        fontSize: 12,
      },
      headerStyle: {
        backgroundColor: "#7986cb",
        color: "#FFF",
        width: 200,
        fontSize: 12,
      },
    };
    columns.push(val);
    for (var i = 2; i <= this.state.param.par_periodes; i++) {
      val = {
        title: i.toString(),
        field: i.toString(),
        type: "numeric",
        editable: "always",
      };
      columns.push(val);
    }

    if (this.state.commandes !== 0 && this.state.commandes.length !== 0) {
      var row = { valeurs: "Commandes clients" };
      for (i = 1; i <= this.state.param.par_periodes; i++) {
        row[i] = 0;
      }
      this.state.commandes.map((cmd, key) => (row[key] = cmd));
      tmp_commandes.push(row);
    }

    return (
      <div className="PeriodView">

        {this.state.param === 0 ? (
          <div>Chargement en cours ...</div>
        ) : (
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h1>
            Exercice - n° {exo} - {this.state.param.par_periodes} périodes -
            code établissement {this.state.game.code}{" "}
            </h1>
            <hr></hr>
            <h2>
              Gestion de la commande client final
            </h2>
            <div className="periodTable">
            <MaterialTable
              className="periodtable"
              title="Saisie"
              options={{
                sorting: false,
                grouping: false,
                draggable: false,
                header: true,
                pageSize: 1,
                pageSizeOptions: [],
                actionsColumnIndex: indexval,
                actionsColumnTitle: " ",
                search: false,
              }}
              columns={columns}
              data={tmp_commandes}
              editable={{
                isDeletable: (rowData) => rowData.role === "Nothing",
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    this.save(newData);
                    setTimeout(() => {
                      resolve();
                    }, 2000);
                  }),
              }}
            />
            </div>
            <NotificationContainer />
          </div>
        )}
                    <hr></hr>

        {this.state.roles.length === 0 ? (
          <div>...</div>
        ) : (
          <div>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <h2>Création et Gestion des roles</h2>

            <MaterialTable
              title="Liste des roles"
              columns={this.state.columns}
              data={this.state.datas}
              actions={[
                {
                  icon: "expand_less",
                  tooltip: "Monter le role",
                  onClick: (event, rowData) => this.upRole(rowData),
                },
                {
                  icon: "expand_more",
                  tooltip: "Descendre le role",
                  onClick: (event, rowData) => this.downRole(rowData),
                },
              ]}
              options={{
                headerStyle: {
                  pointerEvents: "none", // evite les drag and drop et les tris malheureux
                },
                search: false,
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    this.createnewrole(newData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    this.update(newData, oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    this.remove(oldData);
                    setTimeout(() => {
                      resolve();
                    }, 600);
                  }),
              }}
            />
            <hr></hr>
            <h2>
              Reception du dernier rôle ( avant fabrication ) au tour 2
            </h2>
            <FormGroup controlId="par_RxSecondTourProd" bsSize="large">
              <FormControl
                autoFocus
                type="number"
                step={1}
                value={this.state.par_RxSecondTourProd}
                onChange={this.handleChange}
              />
            </FormGroup>
            <hr></hr>

            <Button onClick={this.retour} block bsSize="large" type="submit">
              Revenir aux paramètres
            </Button>

            <Button
              onClick={this.disconnect}
              block
              bsSize="large"
              type="submit"
            >
              Se déconnecter
            </Button>
          </div>
        )}
      </div>
    );
  }
}

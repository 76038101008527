import React, { useRef } from "react";
import '../../viewPlayer.css';
import { Button } from "react-bootstrap";
import MaterialTable from "material-table";
import API from "../../utils/API";
import truck from "../../assets/truck_gif.gif";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import { video } from "video-react";
import img1 from '../../assets/Diapositive1.png'
import img2 from '../../assets/Diapositive2.png'
import i18n from '../../utils/i18n_gameView';

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const images = [
  img1,
  img2
];

export class GamerView extends React.Component {

  state = {
    exo: 0,
    role: 0,
    param: 0,
    tbd: 0,
    par_voir_reliquat_fournisseur: 0,
    infos_tour: [],
    commande: "0",
    wait: false,
    check: null,
    tour: 0,
    last_tour: false,
    roles: [],
    pause: false,
    par_chrono: 60,
    rolenb: 0,
    photoIndex: 0,
    isOpen: false,

    language: i18n.lang_fr,
  };


  disconnect = () => {
    API.logout();
    window.location = "/";
  };

  redo() {
    setInterval(() => {
      console.log('Interval triggered');
    }, 1000);
  }

  valid = async () => {
    try {

      if ( Number(this.state.commande) < 0 || String(this.state.commande)==="" ){
        NotificationManager.warning(
          i18n.wrongorder[this.state.language],
          i18n.stockupdate[this.state.language],
        );
          return;

      }

      await API.sendmyround({ exo: this.state.exo, commande_fournisseur: this.state.commande, infos_tour: this.state.infos_tour, par_voir_reliquat_fournisseur: this.state.par_voir_reliquat_fournisseur, tour: this.state.tour })
        .then(data => this.setState({ wait: data.data.wait, infos_tour: [] }))
        .catch(err => {
          console.log(err);
          this.disconnect();
          return null;
        });

      if (!this.state.wait) {
        clearInterval(this.state.check);
        this.setState({ check: null });

        NotificationManager.success(
          i18n.choicesend[this.state.language],
          i18n.stockupdate[this.state.language],
        );
        this.list();

      } else {
        NotificationManager.warning(
          i18n.waitingplayers[this.state.language],
          i18n.stockupdate[this.state.language],
        );
        if (this.state.check === null) {
          this.setState({
            check: setInterval(() => {
              this.valid();
            }, 10000)
          });
        }
      }

    } catch (error) {
      NotificationManager.error(
        i18n.submissionfaile[this.state.language],
        i18n.stockupdate[this.state.language],
      );
    }
  };

  score = () => {
    NotificationManager.success(
      i18n.viewscore[this.state.language],
      i18n.exercisemngt[this.state.language],
    );
    window.location = "/gamerscore?exo="+this.state.exo+"&role="+Object.keys(this.state.roles).find(key => this.state.roles[key] === this.state.role); ;
  };

  setVal = (newVal) => {
    let tour = this.state.infos_tour[1].current_period;
    console.log("setVal newVal:" + newVal + " tour : " + tour);

    if ( Number(newVal[tour]) < 0 || String(newVal[tour])==="" ) {
      NotificationManager.warning(
        i18n.wrongorder[this.state.language],
        i18n.stockupdate[this.state.language],
      );
        return;

    }
    this.setState({ commande: Number(newVal[tour]), tour: tour });
    NotificationManager.success(
      i18n.saveorder[this.state.language] + newVal[tour],
      i18n.stockupdate[this.state.language],
    );
  };

  list = async () => {
    await API.me().then( data => {
      this.setState({ exo: data.data.me.exo, role: data.data.me.gamerole, rolenb: data.data.me.role, commande: "" });
      }
    ).catch(err => {
      this.disconnect();
      console.log(err);
      return null;
    });

    await API.getrolelist({ exo: this.state.exo }).then(data => {
      let tmpRoles=data.data.roles;
      delete tmpRoles[0];
      this.setState({roles: tmpRoles});
    }).catch(err => {
      this.disconnect();
      console.log(err);
      return null;
    });     

    await API.getonlygameplayer({ exo: this.state.exo })
      .then(data => this.setState({
        infos_tour: data.data.infos_tour, param: data.data.param, tbd: data.data.tbd, par_voir_reliquat_fournisseur: data.data.par_voir_reliquat_fournisseur,
        last_tour: data.data.last_tour, pause: data.data.pause, par_chrono: data.data.timeEnd, language: data.data.param.par_language
      })
      )
      .catch(err => {
        this.disconnect();
        console.log(err);
        return null;
      });

    if (!this.state.pause) {
      clearInterval(this.state.check);
      this.setState({ check: null });
    } else {
      NotificationManager.warning(
        i18n.pause[this.state.language],
        i18n.exercisemngt[this.state.language],
      );
      if (this.state.check === null) {
        this.setState({
          check: setInterval(() => {
            this.list();
          }, 10000)
        });
      }
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };


  NumberList = (props) => {
    const numbers = props.numbers;
    const listItems = numbers.map((number) =>
      <li key={number.toString()}>
        {number}
      </li>
    );
    return (
      <ul>{listItems}</ul>
    );
  }

  componentDidMount() {
    this.list();
  }

  // renderTime = ({ remainingTime }) => {
  //   if (remainingTime === 0) {
  //     return <div className="timer">Transport sur-taxé...</div>;
  //   }
  // }
  // const 
  renderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }
  
    if (remainingTime === 0) {
      return <div className="timer">{i18n.surtax[this.state.language]}</div>;
    }

    const isTimeUp = isNewTimeFirstTick.current;
    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          {remainingTime}
        </div>
      </div>
    );
  };

  render() {

    let rows = [];
    let columns = [];
    let current_infos_tour = [];
    let chrono_duration=1;

    if (this.state.infos_tour.length !== 0) {
      current_infos_tour = this.state.infos_tour[this.state.infos_tour[1].current_period]
      if (this.state.par_chrono<=0) {
        chrono_duration=3600;
      } else {
        chrono_duration=((this.state.par_chrono-Date.now())/1000);
        if (chrono_duration<0){
          chrono_duration=1;
        }
      }

      let column = {
        title: i18n.timeperiod[this.state.language],
        field: "Valeurs",
        type: "string",
        editable: "never",
        cellStyle: {
          width: 230,
          backgroundColor: '#7986cb',
          color: '#FFF',
          fontSize: 12,
        },
        headerStyle: {
          backgroundColor: '#7986cb',
          color: '#FFF',
          width: 230,
          fontSize: 12,
        }
      };
      columns.push(column);


      const pas =  1;

      let tour = current_infos_tour.current_period;
      column = { title: tour.toString(), field: tour.toString(), type: "numeric" };
      columns.push(column);
      tour--;
      for (; tour >= pas; tour--) {
        column = { title: tour.toString(), field: tour.toString(), editable: "never" };
        columns.push(column);
      }

      let line = { Valeurs: i18n.startingInvent[this.state.language], editable: "never", class:"stockrow" };
      line[current_infos_tour.current_period] = current_infos_tour.stock_debut_de_period;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].stock_debut_de_period;
      }
      rows.push(line);

      line = { Valeurs: i18n.expectDelivery[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.livraison_prevues_en_debut_period;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = (this.state.infos_tour[tour].livraison_prevues_en_debut_period)?this.state.infos_tour[tour].livraison_prevues_en_debut_period:0;
      }
      rows.push(line);

      line = { Valeurs: i18n.actualDelivery[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.livraison_en_debut_period;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = (this.state.infos_tour[tour].livraison_en_debut_period)?this.state.infos_tour[tour].livraison_en_debut_period:0;
      }
      rows.push(line);

      if (this.state.par_voir_reliquat_fournisseur) {
        line = { Valeurs: i18n.backOrderSupplier[this.state.language], editable: "never" };
        line[current_infos_tour.current_period] = current_infos_tour.reliquat_fournisseur;
        tour = current_infos_tour.current_period - 1;
        for (; tour >= pas; tour--) {
          line[tour] = (this.state.infos_tour[tour].reliquat_fournisseur)?this.state.infos_tour[tour].reliquat_fournisseur:0;
        }
        rows.push(line);
      }

      line = { Valeurs: i18n.inventoryAfterRx[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.stock_apres_reception;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = (this.state.infos_tour[tour].stock_apres_reception)?this.state.infos_tour[tour].stock_apres_reception:0;
      }
      rows.push(line);

      line = { Valeurs: i18n.customerOrder[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.demande_client;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].demande_client;
      }
      rows.push(line);

      line = { Valeurs: i18n.backOrderCustomer[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.reste_a_servir_client;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].reste_a_servir_client;
      }
      rows.push(line);

      line = { Valeurs: i18n.totalAmount[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.reste_a_servir_client+current_infos_tour.demande_client;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].reste_a_servir_client+this.state.infos_tour[tour].demande_client;
      }
      rows.push(line);

      line = { Valeurs: i18n.deliveryToCustomer[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.expedition;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].expedition;
      }
      rows.push(line);

      line = { Valeurs: i18n.inventoryAfterTX[this.state.language], editable: "never" };
      line[current_infos_tour.current_period] = current_infos_tour.etat_stock_fin_period.toString();
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].etat_stock_fin_period;
      }
      rows.push(line);

      line = { Valeurs: i18n.orderQuantity[this.state.language] };
      line[current_infos_tour.current_period] = this.state.commande;
      tour = current_infos_tour.current_period - 1;
      for (; tour >= pas; tour--) {
        line[tour] = this.state.infos_tour[tour].commande_fournisseur.toString();
      }
      rows.push(line);
    }
    
    const { photoIndex, isOpen } = this.state;

    return (
      <div className="GamerView">
        <link
          rel="stylesheet"
          href="https://video-react.github.io/assets/video-react.css"
        />
        {this.state.infos_tour.length === 0 || (this.state.infos_tour.length!==0 && this.state.pause === true ) ? (
          <div>
            <br/>
            <h2>{i18n.waitduringdeliv[this.state.language]}</h2>
            <div id="videointro">
              <video
              autoPlay
              loop
              src={truck}
              />
            </div>
          </div>
        ) : (
            <div id="GamerView">
              <div className={this.state.par_voir_reliquat_fournisseur?'withRelicat':'withoutRelicat'}>
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <div className="timer-wrapper">
                <CountdownCircleTimer
                  isPlaying
                  duration={chrono_duration}
                  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                >
                  {this.renderTime}
                </CountdownCircleTimer>
              </div>
                <div className="title">
                <h1>{i18n.activity[this.state.language]} - n° {this.state.exo} - {i18n.role[this.state.language]} : {this.state.role} - {current_infos_tour.code} </h1>
                </div>
                <Button  className="buttonAide" variant="success" type="button" onClick={() => this.setState({ isOpen: true })}>
                {i18n.notice[this.state.language]}
                </Button>
                <br/>
                <div className="rolesliste">
                <table><tbody>
                    <tr>{Object.keys(this.state.roles).reverse().map((role) => 
                    <td key={role}> <div className={this.state.roles[role]===this.state.role?'myrole':'normal'} > 
                        &nbsp;{this.state.roles[role]}&nbsp; &rArr;
                        </div>
                    </td>
                    )}
                    <td>
                    &nbsp;Clients&nbsp;
                    </td>
                  </tr>
                  </tbody>
                  </table>
                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % images.length,
                        })
                      }
                    />
                  )}
                </div>

                <div className="gametable">
                <MaterialTable
                  title = { i18n.entry[this.state.language] }
                  options={{
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    header: true,
                    paging: false,
                    pageSize: 11,
                    pageSizeOptions: [],
                    actionsColumnIndex: 1,
                    actionsColumnTitle: " ",
                    search: false,
                    // fixedColumns: {
                    //   left: 2, 
                    //   right: 0
                    // }
                  }}
                  columns={columns}
                  data={rows}
                  editable={{
                    isDeletable: rowData => rowData.role === "Nothing",
                    isEditable: rowData => rowData.Valeurs === i18n.orderQuantity[this.state.language],
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        this.setVal(newData);
                        setTimeout(() => {
                          resolve();
                        }, 600);
                      }),
                  }}
                />
                <br/>
                {this.state.last_tour === true ? (
                    <h1> { i18n.gamecomplete[this.state.language] } </h1>
                  ) : (
                    <Button onClick={this.valid} block bsSize="large" type="submit" disable={this.state.last_tour.toString()} >
                    {i18n.validate[this.state.language]}
                    </Button>
                    )
                }
                <Button onClick={this.score} block bsSize="large" type="submit">
                {i18n.score[this.state.language]}
          </Button>
                <Button onClick={this.disconnect} block bsSize="large" type="submit">
                {i18n.logout[this.state.language]}
          </Button>
              </div>
              </div>
            </div>
          )}
        <NotificationContainer />
      </div>
    );
  }
}
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("token")
};

const Config = require("../utils/config.js");

export default {

  // generic
  login: function(email, password) {
    return axios.post(
      `${Config.burl}/user/login`,
      {
        email,
        password
      },
      {
        headers: headers
      }
    );
  },

  me: function() {
    return axios.get(`${Config.burl}/user/me`, { headers: headers });
  },
  updaterole: function(send) {
    return axios.post(`${Config.burl}/user/updaterole`, send, { headers: headers });
  },
  

  // teacher mgnt
  signupteacher: function(send) {
    return axios.post(`${Config.burl}/user/signupteacher`, send, { headers: headers });
  },
  removeteacher: function(send) {
    return axios.post(`${Config.burl}/user/removeteacher`, send, { headers: headers });
  },
  updateteacher: function(send) {
    return axios.post(`${Config.burl}/user/updateteacher`, send, { headers: headers });
  },
  getallteacher: function() {
    return axios.get(`${Config.burl}/user/getallteacher`, { headers: headers });
  },

  //player mgnt
  signupplayer: function(send) {
    return axios.post(`${Config.burl}/user/signupplayer`, send, { headers: headers });
  },
  removeplayer: function(send) {
    return axios.post(`${Config.burl}/user/removeplayer`, send, { headers: headers });
  },
  updateplayer: function(send) {
    return axios.post(`${Config.burl}/user/updateplayer`, send, { headers: headers });
  },
  listallmyplayers: function() {
    return axios.get(`${Config.burl}/user/listallmyplayers`, { headers: headers });
  },
  listallmygamesnumber: function() {
    return axios.get(`${Config.burl}/game/listallmygamesnumber`, { headers: headers });
  },


// game mgnt
  creategame: function(send) {
    return axios.post(`${Config.burl}/game/creategame`, send, { headers: headers });
  },
  cpygames: function(send) {
    return axios.post(`${Config.burl}/game/cpygames`, send, { headers: headers });
  },
  reset: function(send) {
    return axios.post(`${Config.burl}/game/reset`, send, { headers: headers });
  },
  pausegame: function(send) {
    return axios.post(`${Config.burl}/game/pausegame`, send, { headers: headers });
  },
  unpausegame: function(send) {
    return axios.post(`${Config.burl}/game/unpausegame`, send, { headers: headers });
  },
  getmygames: function(send) {
    return axios.post(`${Config.burl}/game/listallmygames`, send, { headers: headers });
  },
  removegame: function(send) {
    return axios.post(`${Config.burl}/game/removegames`, send, { headers: headers });
  },
  getgameparam: function(send) {
    return axios.post(`${Config.burl}/game/getgameparam`, send, { headers: headers });
  },
  updategameparam: function(send) {
    return axios.post(`${Config.burl}/game/updategameparam`, send, { headers: headers });
  },
  updategamecommandes: function(send) {
    return axios.post(`${Config.burl}/game/updategamecommandes`, send, { headers: headers });
  },
  getonlygame: function(send) {
    return axios.post(`${Config.burl}/game/getonlygame`, send, { headers: headers });
  },
  getonlygameplayer: function(send) {
    return axios.post(`${Config.burl}/game/getonlygameplayer`, send, { headers: headers });
  },

  sendmyround: function(send) {
    return axios.post(`${Config.burl}/game/sendmyround`, send, { headers: headers });
  },

  // role management
  createrole: function(send) {
    return axios.post(`${Config.burl}/game/createrole`, send, { headers: headers });
  },
  updateroles: function(send) {
    return axios.post(`${Config.burl}/game/updateroles`, send, { headers: headers });
  },
  changeroleorder: function(send) {
    return axios.post(`${Config.burl}/game/changeroleorder`, send, { headers: headers });
  },
  updateRxSecondTourProd: function(send) {
    return axios.post(`${Config.burl}/game/updaterxsecondtourprod`, send, { headers: headers });
  }, 
  deleterole: function(send) {
    return axios.post(`${Config.burl}/game/deleterole`, send, { headers: headers });
  },
  getrolelist: function(send) {
    return axios.post(`${Config.burl}/game/getrolelist`, send, { headers: headers });
  },

  getallgamesrounds: function(send) {
    return axios.post(`${Config.burl}/game/getallgamesrounds`, send, { headers: headers });
  },


  Auth: function(role) {
    if (sessionStorage.getItem("token") !== null) {
      if (sessionStorage.getItem("role") === role) return true;
      else
        alert(
          "Accès non autorisé pour ce rôle " +
            role +
            " i/o " +
            sessionStorage.getItem("role")
        );
      sessionStorage.clear();
      return false;
    } else {
      alert("Vous n'êtes pas authentifié");
      sessionStorage.clear();
      return false;
    }
  },

  logout: function() {
    sessionStorage.clear();
  },

};

    /* App.js */
	import React from "react";
	import API from "../../utils/API";
	import { Button } from "react-bootstrap";
	import {Bar, Line} from 'react-chartjs-2';
	// eslint-disable-next-line
	// import ChartDataLabels from 'chartjs-plugin-datalabels';
	const { convertArrayToCSV } = require('convert-array-to-csv');	

	const queryString = require("query-string");

	
    export class GameScore extends React.Component {

		chartColors = {
			red: 'rgb(255, 99, 132)',
			orange: 'rgb(255, 159, 64)',
			yellow: 'rgb(255, 205, 86)',
			green: 'rgb(75, 192, 192)',
			blue: 'rgb(54, 162, 235)',
			purple: 'rgb(153, 102, 255)',
			grey: 'rgb(201, 203, 207)'
		};

		colorArray = [
			'rgb(255, 99, 132)',
			'rgb(255, 159, 64)',
			'rgb(255, 205, 86)',
			'rgb(75, 192, 192)',
			'rgb(54, 162, 235)',
			'rgb(153, 102, 255)',
			'rgb(201, 203, 207)',
			'rgb(255, 99, 132)',
			'rgb(255, 159, 64)',
			'rgb(255, 205, 86)',
			'rgb(75, 192, 192)',
			'rgb(54, 162, 235)',
			'rgb(153, 102, 255)',
			'rgb(201, 203, 207)',
		];

		state = {
			exo: 0,
			role: 0,
			param: 0,
			tbd: 0,
			par_voir_reliquat_fournisseur: 0,
			infos_tour: [],  
			tour: 0,
			last_tour: false,
			roles: [],
			current_period: 1,
			dde_client:[],
			stock_fp:[],
			cde_frn:[],
			cout:[],
			label:[],
			cout_rupture_cumule: [],
			cout_stock_cumule: [],
			rupture: [],
			cout_retard_commande_cumule: [],
			ready: false,
		};

		calcul = () => {
			let initialState = {
				dde_client: [],
				stock_fp: [],
				cde_frn: [],
				cout: [],
				label: [],
				calcul_cout: [],
				cout_rupture_cumule: [],
				cout_stock_cumule: [],
				rupture: [],
				cout_retard_commande_cumule: []
			};
		
			let roles = Object.keys(this.state.roles).map(key => ({ name: key, value: this.state.roles[key] }));
			if (!this.state.infos_tour) {
				return;
			}
			let finalState = roles.reduce((acc, _, rnb) => {
				let info_tour = this.state.infos_tour[rnb];
				console.log("calcul role :",roles[rnb]," ",rnb)
				let dde_client_tmp = [];
				let stock_fp_tmp = [];
				let cde_frn_tmp = [];
				let cout_tmp = [];
				let label_tmp = [];
				let calcul_cout_tmp = 0;
				let cout_rupture_cumule_tmp = [];
				let cout_stock_cumule_tmp = [];
				let rupture_tmp = [];
				let cout_retard_commande_cumule_tmp = [];
		
				info_tour.forEach((tour, i) => {
					dde_client_tmp.push(tour.demande_client);
					stock_fp_tmp.push(tour.etat_stock_fin_period);
					cde_frn_tmp.push(tour.commande_fournisseur);
					rupture_tmp.push(-Number(tour.reste_a_servir_client));
		
					let cout_rupture = Number(tour.reste_a_servir_client) * this.state.param.par_cout_rupture_stock;
					let cout_stock = Number(tour.stock_debut_de_period) * (rnb === 0 ? this.state.param.par_cout_stock_periode_last_role : this.state.param.par_cout_stock_periode);
					let cout_retard = tour.commande_fournisseur_timeout ? (Number(tour.commande_fournisseur) * this.state.param.par_cout_transport) : 0;
		
					cout_rupture_cumule_tmp.push(cout_rupture);
					cout_stock_cumule_tmp.push(cout_stock);
					cout_retard_commande_cumule_tmp.push(cout_retard);
		
					calcul_cout_tmp += cout_rupture + cout_stock + cout_retard;
					cout_tmp.push(calcul_cout_tmp);
					label_tmp.push(`Tour ${i + 1}`);
				});
		
				acc.dde_client.push(dde_client_tmp);
				acc.stock_fp.push(stock_fp_tmp);
				acc.cde_frn.push(cde_frn_tmp);
				acc.cout.push(cout_tmp);
				acc.label.push(label_tmp);
				acc.calcul_cout.push(calcul_cout_tmp);
				acc.cout_rupture_cumule.push(cout_rupture_cumule_tmp);
				acc.cout_stock_cumule.push(cout_stock_cumule_tmp);
				acc.rupture.push(rupture_tmp);
				acc.cout_retard_commande_cumule.push(cout_retard_commande_cumule_tmp);
		
				return acc;
			}, initialState);
		
			this.setState({
				...finalState,
				ready: true,
			});
		
			console.log("calcul fini");
		}
		

		list = async () => {
			const parsed = queryString.parse(window.location.search);
			console.log(parsed.exo.toString());
			this.setState({ exo: parsed.exo, ready: false});
			console.log(this.state.exo);
			await API.getallgamesrounds({ exo: parsed.exo })
			.then(data => {	
				this.setState({
					infos_tour: data.data.infos_tour,
					current_period: data.data.current_period,
					param: data.data.param,
				});
			})
			.catch(err => {
				console.log(err);
				return null;
			});
			await API.getrolelist({ exo: parsed.exo }).then(data => {
				let tmpRoles=data.data.roles;
				delete tmpRoles[0];
				this.setState({roles: tmpRoles},() => {this.calcul()});
			}).catch(err => {
				console.log(err);
				return null;
			});
		};
	
		componentDidMount() {	
			this.list();
		};

		retour = () => {
			window.location = "/TeacherListGame";
		};

		download = (filename, text)  => {
			var element = document.createElement('a');

			var data = new Blob(['\uFEFF',text], {type: 'text/csv'});
			
			var csvURL = window.URL.createObjectURL(data);

			element.setAttribute('href', csvURL);
			element.setAttribute('download', filename);
			element.setAttribute('target', '_self');
			element.style.display = 'none'

			document.body.appendChild(element);

			element.click();
			document.body.removeChild(element);
		};

		getcsvbynum = (a) => {
			this.download("tours_Role_"+a+".csv",convertArrayToCSV(this.state.infos_tour[a]));
		};

		prepareChartData = (a) => {
			const dde_client = this.state.dde_client[a];
			const cde_frn = this.state.cde_frn[a];
			const stock_fp = this.state.stock_fp[a];
			const rupture = this.state.rupture[a];

			const labels = this.state.label[a];
			return { labels, dde_client, cde_frn, stock_fp,rupture };
		};

		renderBarChart = (a) => {
			const { labels, dde_client, cde_frn,stock_fp,rupture } = this.prepareChartData(a);

			const datas = {
				labels: labels,
				responsive: true,
				datasets: [
					{
						label: "Demande client",
						backgroundColor: this.chartColors.yellow,
						borderColor: this.chartColors.yellow,
						data: dde_client,
					},
					{
						label: "Commande fournisseur",
						backgroundColor: this.chartColors.green,
						borderColor: this.chartColors.green,
						data: cde_frn,
					},
					{
						label: "Stock fin de période",
						backgroundColor: this.chartColors.orange,
						borderColor: this.chartColors.orange,
						data: stock_fp,
					},
					{
						label: "Reliquat à livrer au client",
						backgroundColor: this.chartColors.red,
						borderColor: this.chartColors.red,
						data: rupture,
					},

				],
			}
			const options = {
					responsive: true,
					title: {
						display: true,
						text: 'Score'
					},
					plugins: {
						datalabels: {
							align: 'top' ,
							offset: 10,
							backgroundColor: this.chartColors.blue,
							borderRadius: 4,
							color: 'white',
							font: {
								weight: 'bold'
							},
						}
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
			};

			return (
			<Bar key={"line"+a}
				data={datas}
				options={options}
			/>
			);
		}

		prepareLineData = (a) => {
			const cout = this.state.cout[a];
			const cout_stock_cumule = this.state.cout_stock_cumule[a];
			const cout_rupture_cumule = this.state.cout_rupture_cumule[a];
			const cout_retard_commande_cumule = this.state.cout_retard_commande_cumule[a];

			const labels = this.state.label[a];
			return { labels, cout, cout_stock_cumule, cout_rupture_cumule,cout_retard_commande_cumule };
		};

		renderLineChart = (a) => {
			const { labels, cout, cout_stock_cumule,cout_rupture_cumule,cout_retard_commande_cumule } = this.prepareLineData(a);

			const datas = {
				labels: labels,
				responsive: true,
				datasets: [
					{
						label: "Coût total",
					//	backgroundColor: this.chartColors.yellow,
						borderColor: this.chartColors.yellow,
						data: cout,
					},
					{
						label: "Coût Stock",
					//	backgroundColor: this.chartColors.green,
						borderColor: this.chartColors.green,
						data: cout_stock_cumule,
					},
					{
						label: "Cout Rupture",
					//	backgroundColor: this.chartColors.orange,
						borderColor: this.chartColors.red,
						data: cout_rupture_cumule,
					},
					{
						label: "Coût Transport",
					//	backgroundColor: this.chartColors.red,
						borderColor: this.chartColors.green,
						data: cout_retard_commande_cumule,
					},

				],
			}
			const options = {
					responsive: true,
					title: {
						display: true,
						text: 'Score'
					},
					plugins: {
						datalabels: {
							align: 'top' ,
							offset: 10,
							backgroundColor: this.chartColors.blue,
							borderRadius: 4,
							color: 'white',
							font: {
								weight: 'bold'
							},
						}
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
			};

			return (
			<Line key={"bar"+a}
				data={datas}
				options={options}
			/>
			);
		};

		prepareLineDataTotal = (a) => {
			const cout = this.state.cout[a];
			const labels = this.state.label[a];
			return { labels, cout };
		};

		renderLineChartTotal = () => {
			let roles=[];
			Object.keys(this.state.roles).forEach(key => roles.push({name: key, value: this.state.roles[key]}))
			const { labels } = this.prepareLineDataTotal(0);
			let datasetTmp=[];
			let totalCout = Array(labels.length).fill(0);


			for (var rnb=0; rnb < roles.length; rnb++ ) {
				const { cout } = this.prepareLineDataTotal(rnb);
				for (let i = 0; i < cout.length; i++) {
					totalCout[i] += cout[i];
				}
				datasetTmp.push({label: roles[rnb].value, borderColor: this.colorArray[rnb], data: cout })
			}

			datasetTmp.push({
				label: 'Total',
				borderColor: 'black', // Vous pouvez choisir une autre couleur si nécessaire
				data: totalCout,
				borderDash: [5, 5], // Ligne en pointillé pour la différence visuelle
			});
			
			const datas = {
				labels: labels,
				responsive: true,
				datasets: datasetTmp,
			}
			const options = {
					responsive: true,
					title: {
						display: true,
						text: 'Score'
					},
					plugins: {
						datalabels: {
							align: 'top' ,
							offset: 10,
							backgroundColor: this.chartColors.blue,
							borderRadius: 4,
							color: 'white',
							font: {
								weight: 'bold'
							},
						}
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
			};

			return (
			<Line key={"barTotal"}
				data={datas}
				options={options}
			/>
			);
		};

		render() {

			return (
                <div className="GamerScore">
					{this.state.ready !== true ? (
						<div> { this.state.infos_tour ? 
						( "Chargement en cours ..." )
						:( "Exercice non commencé !" )
						}
						</div>
					) : (
						<div>
							<h1> Scores pour la partie </h1>
							{Object.values(this.state.roles).map((role) =>
							<Button key={"button"+role} onClick={this.getcsvbynum.bind(this,0)} bsSize="small">
							CSV role {role}
							</Button>
							)}
							
							{
								Object.values(this.state.roles).map((role,index) => 
								<div key={"div"+role}>
								<h2 >{role}</h2>
								{ this.renderBarChart(index) }
								{ this.renderLineChart(index) }
								<hr/>
								</div>
								)
							}
							<h2>Récapitulatif</h2>
							{ this.renderLineChartTotal() }
						</div>
					)}
					<div>
						<Button onClick={this.retour} block bsSize="large" type="submit">
						Revenir aux exercices
						</Button>
						<br></br>
					</div>
				</div>
			);
		}
    }
import "./App.css";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./components/Dashboard/Dashboard.js";
import { GameRoleChooser } from "./components/GameRoleChooser/GameRoleChooser.js";
import { AdminPanel } from "./components/AdminPanel/AdminPanel.js";
import { GamerView } from "./components/GamerView/GamerView.js";
import { GamerScore } from "./components/GamerView/GamerScore.js";
import { Login } from "./components/Login/Login.js";
import { Signup } from "./components/Signup/Signup.js";
import { PrivateRoute } from "./components/PrivateRoute.js";
import { Admin,Player,Teacher } from "./utils/config.js";
import { TeacherPanel } from "./components/Dashboard/TeacherPanel";
import { TeacherListGame } from "./components/Dashboard/TeacherListGame";
import { TeacherStartPanel } from "./components/Dashboard/StartPanel";
import { PeriodView } from "./components/Dashboard/TeacherPanelRoles"
import { GameScore } from "./components/Dashboard/GameScore";


class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <PrivateRoute path="/dashboard" component={Dashboard} accessRole={Teacher} />
            <PrivateRoute path="/rolechooser" component={GameRoleChooser} accessRole={Player} />
            <PrivateRoute path="/gamerview" component={GamerView} accessRole={Player} />
            <PrivateRoute path="/gamerscore" component={GamerScore} accessRole={Player} />

            <PrivateRoute path="/admin" component={AdminPanel} accessRole={Admin} />
            <PrivateRoute path="/teacherpanel" component={TeacherPanel} accessRole={Teacher} />
            <PrivateRoute path="/teacherlistgame" component={TeacherListGame} accessRole={Teacher} />
            <PrivateRoute path="/teacherstartpanel" component={TeacherStartPanel} accessRole={Teacher} />
            <PrivateRoute path="/roleview" component={PeriodView} accessRole={Teacher} />
            <PrivateRoute path="/scoreview" component={GameScore} accessRole={Teacher} />
          </Switch>
        </div>
      </div>
    );
  }
}
export default App;

module.exports = {
    lang_fr: 0,
    lang_en: 1,
    timeperiod: ["Numéro de période","Time period"],
    startingInvent: ["Stock en début de période","Starting inventory"],
    expectDelivery: ["Réception prévue en provenance du fournisseur","Expected delivery from supplier"],
    actualDelivery: ["Réception réelle en provenance du fournisseur","Actual quantity received from supplier"],
    backOrderSupplier: ["Reliquat du fournisseur","Backorder from supplier (remainder)"],
    inventoryAfterRx: ["Stock après réception","Inventory after delivery"],
    customerOrder: ["Commande du client","Customer order"],
    backOrderCustomer: ["Reliquat à livrer au client", "Backorder to customer (remainder)"],
    totalAmount: ["Total à livrer au client"," Total amount to deliver to customer"],
    deliveryToCustomer: ["Expédition réelle au client","Actual delivery to customer"],
    inventoryAfterTX: ["Stock après expédition", "Inventory after delivery to customer"],
    orderQuantity: ["Décision de commande de réapprovisionnement", "Your order quantity to supplier"],
    validate: ["Valider la période","Validate this period"],
    score: ["Voir mon score","Check my score"],
    logout: ["Se déconnecter", "Logout"],
    entry: ["Saisie", "Entry"],
    gamecomplete: ["Partie Terminée","Game Complete."],
    notice: ["Notice","Instructions"],
    activity: ["Exercice:","Activity"],
    role: ["Rôle:","Role"],
    exercisemngt: ["Gestion des exercices","Exercise management"],
    wrongorder: ["valeur de commande invalide !","Invalid order value!"],
    choicesend: ["Votre choix a été envoyé !","Your choice has been submitted!"],
    waitingplayers: ["En attente des autres joueurs !","Waiting for other players!"],
    submissionfaile: ["L'envoi a rencontré une erreur !","An error occurred during the submission!"],
    viewscore: ["Vue du score","Score view"],
    saveorder: ["Commande enregistrée ","Order saved "],
    pause: ["Jeux en pause !","Game paused!"],
    surtax: ["Transport sur-taxé...","Overcharged transport"],
    waitduringdeliv:["Patientez le temps des livraisons ...","Please wait during the deliveries ..."],
    stockupdate:["mise à jour des stocks","stock update"]
    }

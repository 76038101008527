
import React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import API from "../../utils/API";
import { Player,Admin,Teacher } from "../../utils/config.js";
import { video } from "video-react";
import Regle1 from '../../assets/Regle1.mp4'
import Beta1 from '../../assets/ModeEmploi.pdf'
import Logo from '../../assets/LOGAGILLOGO.png'

export class Login extends React.Component {
  state = {
    email: "",
    password: ""
  };
  send = async () => {
    const { email, password } = this.state;
    if (!email || email.length === 0) {
      return;
    }
    if (!password || password.length === 0) {
      return;
    }
    try {
      const { data } = await API.login(email, password);
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("role", data.role)

      if (data.role === Player)
        window.location = "/gamerview";
      else if (data.role === Teacher)
        window.location = "/teacherstartpanel";
      else if (data.role === Admin)
        window.location = "admin";

    } catch (error) {
      console.error(error);
      alert("Connexion échouée !");
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  render() {
    const { email, password } = this.state;
    return (
      <div>
      <br/>
      <img alt="logo" src={Logo} ></img>
      <div className="Login">
      <link
        rel="stylesheet"
        href="https://video-react.github.io/assets/video-react.css"
      />
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Identifiant</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Mot de passe</ControlLabel>
          <FormControl
            value={password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>

        <Button onClick={this.send} block bsSize="large" type="submit">
          Connexion
        </Button>
        </div>
        <br/>
        <h2><a href={Beta1} download="mode_d_emploi.pdf">Télécharger le mode d'emploi</a></h2>
        <br/>
        <h2>Video de présentation</h2>
        <div id="videointro">
          <video
            controls
            playsInline
            src={Regle1}
          />
        </div>
      </div>
    );
  }
}
